import React from 'react';

import { useMqttState } from 'mqtt-react-hooks';
import { Container } from 'react-bootstrap'

export default function Status() {
  /*
   * Status list
   * - Offline
   * - Connected
   * - Reconnecting
   * - Closed
   * - Error: printed in console too
   */
  const { connectionStatus } = useMqttState();
  return (
    <Container style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{ position:'absolute', bottom:'0' }}>
        {`Status: ${connectionStatus}`}
      </div>
    </Container>
  );
}