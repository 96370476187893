import React from "react";
import { useSubscription } from 'mqtt-react-hooks';

const Billboard = props => {

    const { message: textMessage } = useSubscription(['thewild/demo/text']);
    const { message: billboardMessage } = useSubscription(['thewild/demo/billboard']);
    const imgName = billboardMessage && billboardMessage.message;

  return (
    <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
      <h1 style={{position: 'absolute', top: '20px', width: '100%', color: '#fff', textAlign: 'center', fontSize: '10em'}}>{textMessage && textMessage.message}</h1>
      <img className="billboard" src={`/img/${imgName}.jpg`} alt={imgName} style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: imgName ? 'block' : 'none'
        }} />
    </div>
  );
};

export default Billboard;
