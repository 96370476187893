import React from 'react';
import { useMqttState, useSubscription } from 'mqtt-react-hooks';
import { Row, Col, Image } from 'react-bootstrap'

const ControlImage = props => {
  const topic = 'thewild/demo/billboard';
  
  const { client } = useMqttState();

  const { message } = useSubscription([topic]);
  const billboard = message && message.message;

  function handleClick(message) {
    return client.publish(topic, message, { retain: true });
  }

  const tiles = [
    { name: 'beach', img: '/img/beach.jpg' },
    { name: 'mountain', img: '/img/mountain.jpg' },
    { name: 'sky', img: '/img/sky.jpg' }
  ];

  return (
    <Row className="justify-content-center">
      { tiles.map((tile, index) =>
            <Col xs={4} key={index}>
              <Image src={tile.img} className="img-fluid image-button" onClick={() => handleClick(tile.name)} 
                style={{ border: billboard === tile.name ? '4px solid lightblue' : 'none' }} />
            </Col>
      )}
    </Row>
  );
};

export default ControlImage;