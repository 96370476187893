import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Tabs, Tab } from 'react-bootstrap'
import ControlImage from './ControlImage';
import ControlText from './ControlText';

const Controller = props => {
  const { control } = useParams();
  const navigate = useNavigate();

  function handleSelect(tab) {
    navigate('/controller/' + tab);
  }
  
  return (
    <Container>
      <h1 style={{margin: '30px 0'}}>The Wild: Custom Apps Demo</h1>
      <Tabs className="mb-3" activeKey={control} onSelect={ handleSelect }>
        <Tab eventKey="image" title="Image">
          <ControlImage />
        </Tab>
        <Tab eventKey="text" title="Text">
          <ControlText />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Controller;