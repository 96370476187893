import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { useMqttState, useSubscription } from 'mqtt-react-hooks';

const ControlText = props => {
  const topic = 'thewild/demo/text';
  
  const { client } = useMqttState();

  const { message } = useSubscription([topic]);
  let text = message && message.message;

  const [layout, setLayout] = useState("default");
  const keyboard = useRef();
  
  if(text !== undefined){
    keyboard.current.setInput(text);
  }

  const onChange = input => {
    console.log("Text changed", input);
    client.publish(topic, input, { retain: true });
  };

  const onKeyPress = button => {
    if (button === "{shift}" || button === "{lock}") handleShift();
    if (button === "{numbers}" || button === "{abc}") handleNumbers();
    if (button === "{clear}") onChange('')
  };

  function handleShift() {
    const shiftToggle = layout === "default" ? "shift" : "default";
    setLayout(shiftToggle);
  }
  
  function handleNumbers() {
    let numbersToggle = layout !== "numbers" ? "numbers" : "default";
    setLayout(numbersToggle);
  }

  return (
    <div id="controller">
      <input
        value={text || ' '}
        style={{ fontSize: '3.4em' }}
        readOnly={true}
        placeholder={"Tap on the virtual keyboard to start"}
      />
      <Keyboard
        keyboardRef={r => (keyboard.current = r)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        mergeDisplay={true}
        layoutName={layout}
        layout={{
          default: [
            "Q W E R T Y U I O P",
            "A S D F G H J K L",
            "{shift} Z X C V B N M {backspace}",
            "{numbers} {space} {clear}"
          ],
          shift: [
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{shift} z x c v b n m {backspace}",
            "{numbers} {space} {clear}"
          ],
          numbers: ["1 2 3", "4 5 6", "7 8 9", "{abc} 0 {backspace}"],
        }}
        display= {{
          "{numbers}": "123",
          "{clear}": "clear",
          "{backspace}": "⌫",
          "{shift}": "⇧",
          "{abc}": "ABC"
        }}
      />
    </div>
  );
};

export default ControlText;