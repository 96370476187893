import './FixImport'

import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Connector } from 'mqtt-react-hooks';

import Controller from './components/Controller';
import Billboard from './components/Billboard';
import Status from './Status'

import './App.css';

function App() {
  return (
    <div id="App">
      <Connector brokerUrl="wss://demo.thewild.com:8080">
        <HashRouter>
          <div className="container">
            <Routes>
                <Route path="/controller" element={<Navigate to="/controller/image" />} />
                <Route path="controller/:control" element={<Controller />} />
                <Route path="billboard" element={<Billboard />} />
                <Route path="*" element={<Navigate to="/controller/image" />} />
              </Routes>
            </div>
        </HashRouter>
        <Status />  
      </Connector>
    </div>
  );
}

export default App;
